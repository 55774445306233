import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import axios from 'axios';

import styles from './styles.module.css';

class SocialLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    let embedCount = 0;

    const facebookProfile =
      props.section.data.filter(item => item.type === 'PROFILE/FACEBOOK')[0];
    const instagramProfile =
      props.section.data.filter(item => item.type === 'PROFILE/INSTAGRAM')[0];
    const twitterProfile =
      props.section.data.filter(item => item.type === 'PROFILE/TWITTER')[0];

    if (facebookProfile.active) embedCount += 1;
    if (instagramProfile.active) embedCount += 1;
    if (twitterProfile.active) embedCount += 1;

    this.state = {
      section: props.section,
      title: props.section.data.filter(i => i.type === 'HEADINGS/HEADING-TWO')[0],
      text: props.section.data.filter(i => i.type === 'PARAGRAPH/PARAGRAPH')[0],
      facebookProfile,
      instagramProfile,
      twitterProfile,
      embedCount,
      instagramData: [],
      fullWidth: props.section.data.filter(i => i.type === 'STYLE')[0].full,
    };

    this.InstaImages = this.InstaImages.bind(this);
    this.getInstagramData = this.getInstagramData.bind(this);

  }

  // eslint-disable-next-line class-methods-use-this
  loadTwitterScript() {

    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;

    document.body.appendChild(script);

  }

  getInstagramData(accessToken) {

    const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}&count=9`;

    if (!accessToken || accessToken.length < 8) {

      this.setState({
        instagramData: [],
      });

      return;

    }

    const ax = axios.create({
      withCredentials: false,
    });

    ax.get(url)
      .then((response) => {

        this.setState({
          instagramData: response.data.data,
        });

      })
      .catch(() => {

        this.setState({
          instagramData: [],
        });

      });

  }

  getTitle() {

    const text = this.state.title.data ? `<h2>${this.state.title.data}</h2>` : '';

    return (
      <div
        key={`${this.state.section._id}_layout_0`}
        className={styles[`Title2${this.props.themeData.typography.heading.fontSize}`]}
      >
        {HTMLParser(text)}
      </div>);

  }

  getText() {

    const text = this.state.text.data ? `<span>${this.state.text.data}</span>` : '';

    return (
      <div
        key={`${this.state.section._id}_layout_1`}
        className={styles[`Subtitle${this.props.themeData.typography.heading.fontSize}`]}
      >
        {HTMLParser(text)}
      </div>);

  }

  componentWillMount() {

    this.getInstagramData(this.state.instagramProfile.data.access_token);

  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {

    this.loadTwitterScript();

  }

  InstaImages() {

    const images = [];

    let wide = false;
    let width = 350;

    if (this.state.embedCount < 3) {

      width = 500;
      wide = true;

    }

    const link = `https://instagram.com/${this.state.instagramProfile.data.username}`;

    if (this.state.instagramProfile && this.state.instagramProfile.active
      && this.state.instagramData && this.state.instagramData.length > 0) {

      this.state.instagramData.forEach((data, instaIndex) => {

        const img = (<img
          key={`section_1_instaImage_${instaIndex + 1}`}
          alt=""
          data-index={instaIndex + 1}
          data-mediatype="IMAGE"
          data-disableicon
          style={{
            width: wide ? '154px' : '104px',
            height: wide ? '154px' : '104px',
            marginLeft: '3px',
            marginRight: '3px',
            marginTop: '3px',
            objectFit: 'cover',
          }}
          className="ImgStopAnchor"
          src={data.images.standard_resolution.url}
        />);

        images.push(img);

      });

    }

    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className={styles['instagramLink']}>
        <div style={{
          width: `${width}px`,
          height: wide ? '650px' : '500px',
          flexDirection: 'row',
          overflow: 'hidden',
          backgroundColor: '#FFFFFF',
          padding: '10px',
          paddingBottom: '20px',
          textAlign: 'left',
          marginRight: '5px',
          marginLeft: '5px',
        }}
        >
          <div style={{
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '16px',
            fontSize: '24px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.17px',
            color: '#2a3a42',
          }}
          >Instagram
          </div>
          {images}
        </div>
      </a>);

  }

  render() {

    let scriptAddress = 'https://connect.facebook.net/en_EN/sdk.js#xfbml=1&version=v4.0';

    if (this.props.siteLanguage === 'fi') {

      scriptAddress = 'https://connect.facebook.net/fi_FI/sdk.js#xfbml=1&version=v4.0';

    }

    let tabs = '';
    let first = true;

    if (this.state.facebookProfile.timeline) {

      tabs = 'timeline';
      first = false;

    }

    if (this.state.facebookProfile.events) {

      if (first) {

        tabs = 'events';

      } else {

        tabs = `${tabs},events`;

      }

      first = false;

    }

    if (this.state.facebookProfile.messages) {

      if (first) {

        tabs = 'messages';

      } else {

        tabs = `${tabs},messages`;

      }

      first = false;

    }

    let width = 350;
    let wide = false;

    if (this.state.embedCount < 3) {

      width = 500;
      wide = true;

    }

    return (
      <div className={this.state.fullWidth ? styles['socialContainerFull'] : styles['socialContainer']}>
        {this.state.title.active && this.getTitle()}
        {this.state.text.active && this.getText()}
        <div className={this.state.fullWidth ? styles['socialContentContainerFull'] : styles['socialContentContainer']}>
          {this.state.facebookProfile.active &&
          <div
            key={`fbkey_${this.state.facebookProfile.timeline}${this.state.facebookProfile.events}
                ${this.state.facebookProfile.messages}${this.state.facebookProfile.hideCover}${this.state.facebookProfile.smallHeader}
                ${this.state.facebookProfile.showFacePile}${this.state.fullWidth}`}
            className={wide ? styles['facebookContainerWide'] : styles['facebookContainer']}
          >
            <div id="fb-root" />
            <script
              async
              defer
              crossOrigin="anonymous"
              src={scriptAddress}
            />
            <div
              className="fb-page"
              data-tabs={tabs}
              data-href={`https://www.facebook.com/${this.state.facebookProfile.profile}`}
              data-hide-cover={this.state.facebookProfile.hideCover}
              data-small-header={this.state.facebookProfile.smallHeader}
              data-show-facepile={this.state.facebookProfile.showFacePile}
              data-height={wide ? 650 : 500}
              data-width={width} // 500 is max
              data-adapt-container-width="true"
            />
          </div>
          }
          {this.state.twitterProfile.active &&
          <div
            key={`twitter_profilekey_${this.state.twitterProfile.profile}`}
            className={wide ? styles.twitterContainerWide : styles.twitterContainer}
          >
            <a
              className="twitter-timeline"
              data-height={wide ? 650 : 500}
              data-width={width} // this is actually max width
              href={`https://twitter.com/${this.state.twitterProfile.profile}?ref_src=twsrc%5Etfw`}
            >{this.state.twitterProfile}
            </a>
          </div>
          }
          {this.state.instagramProfile.active && <this.InstaImages />}
        </div>
      </div>
    );

  }

}

SocialLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      profile: PropTypes.string,
    })),
    _id: PropTypes.string,
  }),
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    typography: PropTypes.shape({
      default: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      heading: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
      button: PropTypes.shape({
        name: PropTypes.string,
        weight: PropTypes.string,
        lineHeight: PropTypes.string,
        letterSpacing: PropTypes.string,
        fontSize: PropTypes.string,
        textTransform: PropTypes.string,
      }),
    }),
  }),
};

export default CSSModules(
  SocialLayout, styles,
  { allowMultiple: true },
);
